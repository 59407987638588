// Common compare
function comp(a, b){
    let comparison = 0;
    if (a > b) {
      comparison = 1;
    } else if (a < b) {
      comparison = -1;
    }
    return comparison;
  }
  
  export default class Sort {
    // Device ID
    static compareDeviceAsc(a, b) {
      const strA = a.deviceId;
      const strB = b.deviceId;
      return comp(strA, strB);
    }
    static compareDeviceDesc(a, b) {
      const strA = a.deviceId;
      const strB = b.deviceId;
      return comp(strB, strA);
    }
  
    // Name
    static compareNameAsc(a, b) {
      let strA = a.name;
      let strB = b.name;
      if (a.name)
        strA = a.name.toUpperCase();
      if (b.name)
        strB = b.name.toUpperCase();
      return comp(strA, strB);
    }
    static compareNameDesc(a, b) {
      let strA = a.name.toUpperCase();
      let strB = b.name.toUpperCase();
      if (a.name)
        strA = a.name.toUpperCase();
      if (b.name)
        strB = b.name.toUpperCase();
      return comp(strB, strA);
    }
    
    // GroupName
    static compareGroupNameAsc(a, b) {
      let strA = a.group_name;
      let strB = b.group_name;
      if (a.group_name)
        strA = a.group_name.toUpperCase();
      if (b.group_name)
        strB = b.group_name.toUpperCase();
      return comp(strA, strB);
    }
    static compareGroupNameDesc(a, b) {
      let strA = a.group_name.toUpperCase();
      let strB = b.group_name.toUpperCase();
      if (a.group_name)
        strA = a.group_name.toUpperCase();
      if (b.group_name)
        strB = b.group_name.toUpperCase();
      return comp(strB, strA);
    }

    // Status
    static compareStatusAsc(a, b) {
      let strA = a.status;
      let strB = b.status;
      if (a.status)
        strA = a.status.toUpperCase();
      if (b.status)
        strB = b.status.toUpperCase();
      return comp(strA, strB);
    }
    static compareStatusDesc(a, b) {
      let strA = a.status.toUpperCase();
      let strB = b.status.toUpperCase();
      if (a.status)
        strA = a.status.toUpperCase();
      if (b.status)
        strB = b.status.toUpperCase();
      return comp(strB, strA);
    }

  }
  