import React from "react"
import PropTypes from "prop-types"
import MapBrowser from "../map/MapBrowser"
import API from "../API"
import Select from 'react-select';
import { ListGroup, Button, Form } from 'react-bootstrap'
import { Card, CardHeader, CardBody } from 'reactstrap';


var this_obj;
var websocket;
let listChange = 0;
export default class GeofenceForm extends React.Component {

  constructor(props) {
    super(props);
    this_obj = this;
  }

  componentDidMount() {
    this.initializeWebsocket(this.props.deviceMap);
  }

  componentWillUnmount(){
    websocket.close();
    console.log("ws closed.");
  }

  initializeWebsocket(deviceMap){
    let websocketURL = this.props.map_ws_endpoint + this.props.organization_id;
    console.log("[WS Endpoint] "+websocketURL)
    websocket = new WebSocket(websocketURL);

    websocket.onopen = function(event){
      console.log("ws opened.");
    }

    websocket.onmessage = function (event) {
      let data = JSON.parse(event.data);
      console.log(data);
      let device;
      let deviceMap = this_obj.props.deviceMap;
      for(let i in deviceMap){
        if(deviceMap[i].deviceId == data.id){
          //デバイスのプロパティをアップデート
          device = deviceMap[i]
          break;
        }
      }

      if (device != null){
        //デバイスを移動
        device.ws_move(data.lat, data.long, data.spd), data.time;
      }
    }
  }


  render () {
    return (
          <MapBrowser center={this.props.center} zoom={this.props.zoom}
                      handleClick={this.props.handleClick}
                      lines={this.props.lines}
                      circles={this.props.circles}
                      polygons={this.props.polygons}
                      vbuoys={this.props.vbuoys}
                      geojsons={this.props.geojsons}
                      deviceMap={this.props.deviceMap}
                      showPins={this.props.showPins}
                      formlines={this.props.formlines}
                      formcircles={this.props.formcircles}
                      formpolygons={this.props.formpolygons}
                      formvbuoys={this.props.formvbuoys}
          />
    );
  }
}
