import React, { Component } from 'react';
import L from 'leaflet';
import { Marker, CircleMarker, Popup, Polyline, Tooltip } from 'react-leaflet';
import images from '../images';
import '!style-loader!css-loader!./MainPlayer.css'; 
import { Button } from 'react-bootstrap'

const triage1 = '#4db56a';//Green
const triage2 = '#ff0000';//Red
const triage3 = '#ff0000';//Red
const triage4 = '#FFC800';//Yellow
const triage6 = '#33CCFF';//blue





export default class Emergency extends Component {
  constructor(props){
    super(props);
    const { lat, long, phone_number, location, created_at, triage, history  } = this.props;
    const markerHtmlStyles = `
      background-color: ${this.getColor(triage)};
      width: 1.0rem;
      height: 1.0rem;
      display: block;
      left: -0.5rem;
      top: 1.3rem;
      position: relative;
      border-radius: 3rem 3rem 0;
      transform: rotate(45deg);
      border: 0px solid #FFFFFF`

    this.state = {
      mstyle: markerHtmlStyles,
      show_label : false,
			pos: [],
    }
  }


  UNSAFE_componentWillMount(){
    const iconAnchor = [0,24];//icon size 16X16
    const markerHtmlStyles = this.state.mstyle;
    let iconHTML = `<span style="${markerHtmlStyles}" />`

    this.icon = new L.DivIcon({ 
              className: "my-custom-pin",
              iconAnchor: iconAnchor,
              labelAnchor: [-6, 0],
              popupAnchor: [0, -36],
              html: iconHTML,
              });

    this.setState({
      mstyle: markerHtmlStyles,
      show_label : false,
    })

  }


  TooltipHTML(location, phone_number, comment, triage, id, created_at){
    return (
      <span>
        <div>{location}</div>
        <div> 
          ID: <span>{id}</span><br/>
          Triage: <span>{triage}</span><br/>
          Location: <span>{location}</span><br/>
          Phone: <a href={'tel:'+phone_number}>{phone_number}</a><br/>
          Comment: <span>{comment}</span><br/>
          Emergency at: <span>{this.timeFormatter(created_at)}</span>
        </div>
      </span>
    );
  }

  //時間の表示形式の調整、いまは使わないが
  timeFormatter(t){
    if(!t)
      return
    return t
  }

  getColor(triage){
		let color = triage1;
		switch(triage){
			case 1:
			color = triage1;
			break;

			case 2:
			color = triage2;
			break;

			case 3:
			color = triage3;
			break;

			case 4:
			color = triage4;
			break;

			case 5:
			color = triage1;
			break;

			case 6:
			color = triage6;
			break;

			default:
			color = triage1;
		}
		return color;
  }

  getTriageString(triage){
		let str = "";
		switch(triage){
			case 0:
			str = "未選択";
			break;
			case 1:
			str = "";
			break;
			case 2:
			str = "緊急";
			break;
			case 3:
			str = "緊急";
			break;
			case 4:
			str = "準緊急";
			break;
			case 5:
			str = "負傷/体調不良";
			break;
			case 6:
			str = "道具故障";
			break;
			default:
			str = "例外";
		}
		return str;
  }


  render() {
    const EmergencyM = <CircleMarker color={this.getColor(this.props.triage)} center={[this.props.lat, this.props.long]} radius={19} />;

    const tooltipHTML = this.TooltipHTML(this.props.location, this.props.phone_number, this.props.comment, this.getTriageString(this.props.triage), this.props.id, this.props.created_at);

		let json = JSON.parse(this.props.history)
		let pos = []
		for (var i in json) {
			pos.push([json[i][1], json[i][0]])
		}

    return (
      <span>
        <Polyline key={Math.random()} positions={pos} color={this.getColor(this.props.triage)}  opacity={0.4} />
        <Marker
          position={[this.props.lat, this.props.long]}
          icon={this.icon}
          eventHandlers={{
            click: (e) => {
							//ShowLabel disableの場合
							this.setState({
								isClicked: !this.state.isClicked,
							});
            },}}
        >


          { true &&(
          <Tooltip className={"tooltip-player"} permanent={true} direction="right" offset={[5, 2]} interactive={true}>
            <div>{!this.state.isClicked? "No:"+this.props.id +"/" + (this.props.location? this.props.location : this.getTriageString(this.props.triage) )  : tooltipHTML}</div>
          </Tooltip>)}
        </Marker>

      {EmergencyM}

     </span>
    );
  }
}
