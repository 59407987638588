import React, {Component} from "react";
import ReactAsyncTable from 'react-async-table';
import { Card, CardHeader, CardBody, Container, Row, Col } from 'reactstrap';
import { Button } from 'react-bootstrap';
import '!style-loader!css-loader!font-awesome/css/font-awesome.min.css';
import '!style-loader!css-loader!./PagiList.css';


const ExampleLoader = () => (
  <p>Loading...</p>
);

var instance;


class PagiList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      search: '',
      page: 1,
      items: this.props.data,
      currentPage: 1,
      itemsPerPage: this.props.itemsPerPage,
      totalItems: 0,
      async_mode: 0,
    };

    this.onChangePage = this.onChangePage.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onInsert = this.onInsert.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onMultipleDelete = this.onMultipleDelete.bind(this);
    this.onChangeListnum = this.onChangeListnum.bind(this);
  }

  onChangeListnum(e) {
    this.setState({
      itemsPerPage: Number(e.target.value),
      page: 1,
    });
    this.setState({ isLoading: true });
    this.fakeAsyncAction()
  }

  fakeAsyncAction() {
    setTimeout(() => {
      const { search, page, itemsPerPage } = this.state;
      const currentIndex = (page - 1) * itemsPerPage;
      const filteredItems = this.props.data.filter(item => item.searchstr.toLowerCase().indexOf(search.toLowerCase()) !== -1);
      this.setState(() => ({
        isLoading: false,
        items: filteredItems.slice(currentIndex, currentIndex + itemsPerPage),
        totalItems: filteredItems.length,
        async_mode: 0,
      }));
    }, 200);
  }

  //ショートカットボタン１
  customAsyncAction(e) {
    setTimeout(() => {
      const { search, page, itemsPerPage } = instance.state;
      const currentIndex = (page - 1) * itemsPerPage;
      let filteredItems
      if(e){
        filteredItems = instance.props.data.filter(item => item.searchstr.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1);
        instance.setState(() => ({
          isLoading: false,
          items: filteredItems.slice(currentIndex, currentIndex + itemsPerPage),
          totalItems: filteredItems.length,
          async_mode: 1,
          status: e.target.value,
        }));
      }else{
        filteredItems = instance.props.data.filter(item => item.searchstr.toLowerCase().indexOf(instance.state.status.toLowerCase()) !== -1);
        instance.setState(() => ({
          isLoading: false,
          items: filteredItems.slice(currentIndex, currentIndex + itemsPerPage),
          totalItems: filteredItems.length,
          async_mode: 1,
        }));
      }
    }, 200);
  }

  //ショートカットボタン2
  customAsyncAction2(e) {
    setTimeout(() => {
      const { search, page, itemsPerPage } = instance.state;
      const currentIndex = (page - 1) * itemsPerPage;
      let filteredItems;
      if(e){
        if(e.target.value == "gps"){
          filteredItems = instance.props.data.filter(item => item.fix > 0);
        }else{
          filteredItems = instance.props.data.filter(item => item.fix == 0);
        }
        instance.setState(() => ({
          isLoading: false,
          items: filteredItems.slice(currentIndex, currentIndex + itemsPerPage),
          totalItems: filteredItems.length,
          async_mode: 2,
          status: e.target.value,
        }));
      }else{
        if(instance.state.status == "gps"){
          filteredItems = instance.props.data.filter(item => item.fix > 0);
        }else{
          filteredItems = instance.props.data.filter(item => item.fix == 0);
        }
        instance.setState(() => ({
          isLoading: false,
          items: filteredItems.slice(currentIndex, currentIndex + itemsPerPage),
          totalItems: filteredItems.length,
          async_mode: 2,
        }));
      }
    }, 200);
  }

  //ショートカットボタン3
  customAsyncAction3(e) {
    setTimeout(() => {
      const { search, page, itemsPerPage } = instance.state;
      const currentIndex = (page - 1) * itemsPerPage;
      let filteredItems;
      if(e){
        filteredItems = instance.props.data.filter(item => item.battery_p <= e.target.value);
        instance.setState(() => ({
          isLoading: false,
          items: filteredItems.slice(currentIndex, currentIndex + itemsPerPage),
          totalItems: filteredItems.length,
          async_mode: 3,
          status: e.target.value,
        }));
      }else{
        filteredItems = instance.props.data.filter(item => item.battery_p <= instance.state.status);
        instance.setState(() => ({
          isLoading: false,
          items: filteredItems.slice(currentIndex, currentIndex + itemsPerPage),
          totalItems: filteredItems.length,
          async_mode: 3,
        }));
      }
    }, 200);
  }

  //ショートカットボタン4
  customAsyncAction4(e) {
    setTimeout(() => {
      const { search, page, itemsPerPage } = instance.state;
      const currentIndex = (page - 1) * itemsPerPage;
      let filteredItems;
      if(e){
        filteredItems = instance.props.data.filter(item => item.in_use == true);
        instance.setState(() => ({
          isLoading: false,
          items: filteredItems.slice(currentIndex, currentIndex + itemsPerPage),
          totalItems: filteredItems.length,
          async_mode: 4,
          status: e.target.value,
        }));
      }else{
        //filteredItems = instance.props.data.filter(item => item.in_use == instance.state.status);
        filteredItems = instance.props.data.filter(item => item.in_use == true);
        instance.setState(() => ({
          isLoading: false,
          items: filteredItems.slice(currentIndex, currentIndex + itemsPerPage),
          totalItems: filteredItems.length,
          async_mode: 4,
        }));
      }
    }, 200);
  }




  onChangePage(page) {
    this.setState({ page, isLoading: true });
    if(this.state.async_mode == 0)
      this.fakeAsyncAction();
    else if(this.state.async_mode == 1)
      this.customAsyncAction();
    else if(this.state.async_mode == 2)
      this.customAsyncAction2();
    else if(this.state.async_mode == 3)
      this.customAsyncAction3();
    else if(this.state.async_mode == 4)
      this.customAsyncAction4();
    else
      this.fakeAsyncAction();
  }

  onSearch(search) {
    this.setState({ search, page: 1, isLoading: true });
    this.fakeAsyncAction();
  }

  onSort(sortField, sortOrder) {
    let items = instance.state.items.slice();
    for(let i in instance.props.columuns){
      if(instance.props.columuns[i].dataField == sortField){
        if( sortOrder == 'asc')
          items.sort( instance.props.columuns[i].afunc );
        else
          items.sort( instance.props.columuns[i].dfunc );
        break;
      }
    }
    instance.setState({items: items});
  }

  onInsert() {
    console.log('onInsert handler');
    console.log(this.props.insertFormUrl);
    this.props.history.push(this.props.insertFormUrl);
  }

  onEdit(rowID) {
    this.props.onEdit(rowID);
  }

  onDelete(rowID, page) {
    console.log('onDelete handler');
    console.log('id:', rowID);
    console.log('page:', page);
  }

  onHeaderAction(e) {
    instance.setState({
      itemsPerPage: Number(e.target.value),
      page: 1,
    });
    instance.setState({ isLoading: true });
    instance.fakeAsyncAction()
  }

  onAction(type, row) {
    console.log('onAction handler');
    console.log('type:', type);
    console.log('row:', row);
  }

  onMultipleDelete(values, page) {
    console.log('onMultipleDelete handler');
    console.log('ids:', values);
    console.log('page:', page);
  }

  onColumnClick(columnKey, row) {
    console.log('onColumnClick handler');
    console.log('column:', columnKey);
    console.log('row data:', row);
  }


  componentDidMount() {
    this.fakeAsyncAction();
  }

  render() {
    instance = this;
    const { isLoading, items, page, search, itemsPerPage, totalItems, listnum  } = this.state;
    const customizeField1 = this.props.customizeField1;
    const customizeField2 = this.props.customizeField2;
    const customizeField3 = this.props.customizeField3;

    let data = [
      { value: 10, label: '10' },
      { value: 50, label: '50' },
      { value: 100, label: '100' },
      { value: 200, label: '200' },
      { value: 400, label: '400' },
    ];


    let ExampleHeaderActions = "";
    if(this.props.use_header_actions){
        ExampleHeaderActions = ({ onHeaderAction }) => (
        <Container>
          <Row>
            <Col sm="1"> 表示件数</Col>
            <Col sm="1">
              <select defaultValue={itemsPerPage} onChange={ e => onHeaderAction(e) }>
                { data.map( d => <option value={d.value}>{d.label}</option>)}
              </select>
            </Col>
            <Col sm="1">
              <Button className={'badge_online'} variant="outline-success" size="sm" value="online" onClick={this.customAsyncAction}>Online</Button>
            </Col>
            <Col sm="1">
              <Button className={'badge_offline'} variant="outline-danger" size="sm" value="offline" onClick={this.customAsyncAction}>Offline</Button>
            </Col>
            <Col sm="1">
              <Button className={'badge_gps'} variant="outline-info" size="sm" value="gps" onClick={this.customAsyncAction2}>GPS受信</Button>
            </Col>
            <Col sm="1">
              <Button className={'badge_nogps'} variant="outline-light" size="sm" value="nogps" onClick={this.customAsyncAction2}>GPS未受信</Button>
            </Col>
            <Col sm="1">
              <Button className={'badge_batt_50'} variant="outline-light" size="sm" value="50" onClick={this.customAsyncAction3}>Batt 50%↓</Button>
            </Col>
            <Col sm="1">
              <Button className={'badge_batt_30'} variant="outline-light" size="sm" value="30" onClick={this.customAsyncAction3}>Batt 30%↓</Button>
            </Col>
            <Col sm="1">
              <Button className={'badge_online'} variant="outline-success" size="sm" value="true" onClick={this.customAsyncAction4}>使用中</Button>
            </Col>
          </Row>
        </Container>
      );
    }


    return (
      <Container>
        <Card>
          <CardHeader>
              <b> {this.props.title} </b>
          </CardHeader>
          <CardBody>
            <ReactAsyncTable
              keyField="id"
              isLoading={isLoading}
              query={search}
              requestFailed={false}
              columns={this.props.columuns }
              items={items}
              tableClass="table-outline"
              tableHeaderClass="htead-light"
              currentPage={page}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              delay={300}

              loader={ExampleLoader}
              headerActions={ExampleHeaderActions}
              actionsComponent={this.props.actionsComponent}
              expandableRowComponent={this.props.ExpandableRowComponent}
 
              options={this.props.options}
              translations={{
                searchPlaceholder: 'Search...',
                addButton: 'Add',
                deleteButton: 'Delete',
                sortTitle: 'Sort',
                actionsColumnTitle: '変更',
                editAction: 'Edit',
                deleteAction: 'Delete',
                noDataText: 'No data found',
                requestFailedText: 'API request failed',
                paginationFirst: '最初',
                paginationLast: '最後'
              }}
              icons={{
                addButtonIcon: 'fa fa-plus',
                deleteButtonIcon: 'fa fa-trash',
                tooltipIcon: 'fa fa-question',
                sortIcon: 'fa fa-sort',
                expandIcon: 'fa fa-expand',
                editActionIcon: 'fa fa-pencil',
                deleteActionIcon: 'fa fa-trash'
              }}
              onChangePage={this.onChangePage}
              onSearch={this.onSearch}
              onSort={this.onSort}
              onColumnClick={this.onColumnClick}
              onInsert={this.onInsert}
              onEdit={this.onEdit}
              //onDelete={this.onDelete}
              onDelete={this.props.onDelete}
              onHeaderAction={this.onHeaderAction}
              onAction={this.props.onAction}
              onMultipleDelete={this.onMultipleDelete}
              //deleteFunc={this.props.deleteFunc}
            />
          </CardBody>
        </Card>
        <div>Showing {(page-1) * itemsPerPage+1} to {(page-1) * itemsPerPage + items.length} of {this.state.totalItems} entries</div>
      </Container>
    );

  }
}

export default PagiList;
