// Common compare
function comp(a, b){
    let comparison = 0;
    if (a > b) {
      comparison = 1;
    } else if (a < b) {
      comparison = -1;
    }
    return comparison;
  }

export default class Sort {
  // 機器種別
  static compareSensorTypeAsc(a, b) {
    const strA = a.category;
    const strB = b.category;
    return comp(strA, strB);
  }
  static compareSensorTypeDesc(a, b) {
    const strA = a.category;
    const strB = b.category;
    return comp(strB, strA);
  }

  // Device ID
  static compareDeviceAsc(a, b) {
    const strA = a.deviceId;
    const strB = b.deviceId;
    return comp(strA, strB);
  }
  static compareDeviceDesc(a, b) {
    const strA = a.deviceId;
    const strB = b.deviceId;
    return comp(strB, strA);
  }

  // Athlete Name
  static compareAthleteNameAsc(a, b) {
    let strA = a.athlete_name;
    let strB = b.athlete_name;
    if (a.name)
      strA = a.athlete_name.toUpperCase();
    if (b.name)
      strB = b.athlete_name.toUpperCase();
    return comp(strA, strB);
  }
  static compareAthleteNameDesc(a, b) {
    let strA = a.athlete_name.toUpperCase();
    let strB = b.athlete_name.toUpperCase();
    if (a.name)
      strA = a.athlete_name.toUpperCase();
    if (b.name)
      strB = b.athlete_name.toUpperCase();
    return comp(strB, strA);
  }

  // Name
  static compareNameAsc(a, b) {
    let strA = a.name;
    let strB = b.name;
    if (a.name)
      strA = a.name.toUpperCase();
    if (b.name)
      strB = b.name.toUpperCase();
    return comp(strA, strB);
  }
  static compareNameDesc(a, b) {
    let strA = a.name.toUpperCase();
    let strB = b.name.toUpperCase();
    if (a.name)
      strA = a.name.toUpperCase();
    if (b.name)
      strB = b.name.toUpperCase();
    return comp(strB, strA);
  }
  
  // Inuse
  static compareInuseAsc(a, b) {
    let strA = a.inuse;
    let strB = b.inuse;
    if (a.name)
      strA = a.inuse.toUpperCase();
    if (b.name)
      strB = b.inuse.toUpperCase();
    return comp(strA, strB);
  }
  static compareInuseDesc(a, b) {
    let strA = a.inuse.toUpperCase();
    let strB = b.inuse.toUpperCase();
    if (a.name)
      strA = a.inuse.toUpperCase();
    if (b.name)
      strB = b.inuse.toUpperCase();
    return comp(strB, strA);
  }

  // GroupName
  static compareGroupNameAsc(a, b) {
    let strA = "";
    let strB = "";
    if (a.group_name_and_color)
      strA = a.group_name_and_color.toUpperCase();
    if (b.group_name_and_color)
      strB = b.group_name_and_color.toUpperCase();
    return comp(strA, strB);
  }

  static compareGroupNameDesc(a, b) {
    let strA = "";
    let strB = "";
    if (a.group_name_and_color)
      strA = a.group_name_and_color.toUpperCase();
    if (b.group_name_and_color)
      strB = b.group_name_and_color.toUpperCase();
    return comp(strB, strA);
  }

  // Status
  static compareStatusAsc(a, b) {
    let strA = "";
    let strB = "";
    if (a.status)
      strA = a.status;
    if (b.status)
      strB = b.status;
    return comp(strA, strB);
  }
  static compareStatusDesc(a, b) {
    let strA = "";
    let strB = "";
    if (a.status)
      strA = a.status;
    if (b.status)
      strB = b.status;
    return comp(strB, strA);
  }

  // Access
  static compareAccessAsc(a, b) {
    let strA = "";
    let strB = "";
    if (a.access)
      strA = a.access;
    if (b.access)
      strB = b.access;
    return comp(strA, strB);
  }
  static compareAccessDesc(a, b) {
    let strA = "";
    let strB = "";
    if (a.access)
      strA = a.access;
    if (b.access)
      strB = b.access;
    return comp(strB, strA);
  }

  // Gps
  static compareGpsAsc(a, b) {
    let strA = "";
    let strB = "";
    if (a.gps)
      strA = a.gps;
    if (b.gps)
      strB = b.gps;
    return comp(strA, strB);
  }
  static compareGpsDesc(a, b) {
    let strA = "";
    let strB = "";
    if (a.gps)
      strA = a.gps;
    if (b.gps)
      strB = b.gps;
    return comp(strB, strA);
  }

  // Battery
  static compareBatteryAsc(a, b) {
    let strA=-1;
    let strB=-1;
    if (a.battery)
      strA = a.battery_p;
    if (b.battery)
      strB = b.battery_p;
    return comp(strA, strB);
  }
  static compareBatteryDesc(a, b) {
    let strA = -1;
    let strB = -1;
    if (a.battery)
      strA = a.battery_p;
    if (b.battery)
      strB = b.battery_p;
    return comp(strB, strA);
  }

  // Loan
  static compareLoanAsc(a, b) {
    const strA = a.is_loan;
    const strB = b.is_loan;
    return comp(strA, strB);
  }
  static compareLoanDesc(a, b) {
    const strA = a.is_loan;
    const strB = b.is_loan;
    return comp(strB, strA);
  }

}
