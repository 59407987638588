import L from "leaflet"
import Device from "../device/Device"
import images from '../images';
import '../map/MainPlayer.css';

export default class ReplayData {

  constructor(){
    this.replayDevices = null;
  }

  static Init(data) {
    this.logs = data.logs ? data.logs : [] ;
    this.geofences = data.geofences ? data.geofences : [];
    this.map_center = data.center ? JSON.parse(data.center) : null;
    this.replayDevices = data.devices.map(function(d, i) {
      return new Device(i, d)
    })

    this.center = function(){
      return this.middleOf(this.startCoordinate(), this.endCoordinate())
    }

    this.middleOf = function(coord1, coord2){
      var p1  = turf.point(coord1.reverse())
      var p2  = turf.point(coord2.reverse())
      var midpoint = turf.midpoint(p1, p1);
      return midpoint.geometry.coordinates.reverse()
    }

    this.max_time = function(){
      var max_time = new Date("Wed, 12 Sep 1000 00:38:03 GMT");
      var i
      for (i = 0; i < this.replayDevices.length; i++){
        var time = this.replayDevices[i].max_time()
        if (time > max_time){
          max_time = time
        }
      }
      return max_time
    }

    this.min_time = function(){
      var min_time = new Date("Wed, 12 Sep 7000 00:38:03 GMT");
      var i
      for (i = 0; i < this.replayDevices.length; i++){
        var time = this.replayDevices[i].min_time()
        if (time < min_time){
          min_time = time
        }
      }
      return min_time
    }

    this.move = function(time){
      const format_time = time.getFullYear()
        + '-' + ('0' + (time.getMonth() + 1)).slice(-2)
        + '-' + ('0' + time.getDate()).slice(-2)
        + ' ' + ('0' + time.getHours()).slice(-2)
        + ':' + ('0' + time.getMinutes()).slice(-2)
        + ':' + ('0' + time.getSeconds()).slice(-2);
      const logs = this.logs;

      this.replayDevices.forEach(function(replayDevice, i) {
        let final_geos = []; // ジオフェンスの最終状態を格納するための配列
        let geo_hash = {}; // geofence_id

        if(logs.length != 0){
          for(let idx = 0; idx < logs.length; idx++){
            // ジオフェンスの発生時間がリプレイの時間より前の時、かつ、シリアル番号が一致する時に発火
            if( (logs[idx].created_at <= format_time && logs[idx].device_id == replayDevice.deviceId) && ((new Date(format_time) - new Date(logs[idx].created_at))/1000)<30 ){//15秒以内のイベント
              // event_typeが7の時、ジオフェンスに入る
              if(logs[idx].event_type == 7){
                //hashに登録
                geo_hash[logs[idx].geofence_id] = logs[idx].geofence
              // event_typeが8の時、ジオフェンスから出る
              } else if(logs[idx].event_type == 8){
                //hashから削除
                delete geo_hash[logs[idx].geofence_id]
              // event_typeが10の時、emergencyフラグを立てる
              } else if(logs[idx].event_type == 10){
                replayDevice.emergency = true
                replayDevice.emergency_type = 10;
                replayDevice.emergency_signaled_at = logs[idx].created_at
                replayDevice.emergency_reason = "速度が指定値を下回っています";
              // event_typeが11の時、emergencyフラグを下す
              } else if(logs[idx].event_type == 11){
                replayDevice.emergency = false
                replayDevice.emergency_signaled_at = logs[idx].created_at
                replayDevice.emergency_reason = null;
              }
            }
          }
        }

        //ジオフェンス表示の処理
        for (let key in geo_hash) {
          final_geos.push(geo_hash[key])
          replayDevice.searchstr += geo_hash[key] // searchstrに対象ジオフェンスを追加
        }
        replayDevice.geofence = final_geos.join(',');  // Deviceのgeofenceにジオフェンスの最終状態を格納
        replayDevice.move(time)
      })
    }
  }
}
