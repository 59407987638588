import React from "react"
import PropTypes from "prop-types"
import MapBrowser from "../ws_map/Main"
import I from "../ws_map/Initialize"
import API from "../API"
import Select from 'react-select';
import { ListGroup, Button, Form } from 'react-bootstrap'
import { Card, CardHeader, CardBody } from 'reactstrap';
import { SketchPicker } from 'react-color';


export default class GeofenceForm extends React.Component {

  constructor(props) {
    super(props);
    const { geofence_type, geofence } = this.props;

    this.state = {
      geofence_name: geofence.name ? geofence.name : '',
      geofence_type: geofence.geofence_type,
      radius: geofence.radius > 0 ? geofence.radius : 100,
      lines: geofence.geofence_type == 1 ? [{geofence_name: geofence.name, color_code:geofence.color_code, vertexes: JSON.parse(geofence.vertexes)}] : [],
      circles: geofence.geofence_type == 2 ? [{geofence_name: geofence.name, color_code:geofence.color_code, vertexes: JSON.parse(geofence.vertexes)}] : [],
      polygons: geofence.geofence_type == 3 ? [{geofence_name: geofence.name, color_code:geofence.color_code, vertexes: JSON.parse(geofence.vertexes)}] : [],
      vbuoys: geofence.geofence_type == 4 ? [{geofence_name: geofence.name, color_code:geofence.color_code, vertexes: JSON.parse(geofence.vertexes)}] : [],
      geojsons: geofence.geofence_type == 5 ? [{geofence_name: geofence.name, color_code:geofence.color_code, vertexes: JSON.parse(geofence.vertexes)}] : [],
      loading: false,
      submit_status: false,
      color_code: geofence.color_code? geofence.color_code : "#00000",
      push_event_log: geofence.push_event_log? geofence.push_event_log : false,
      push_speed_log: geofence.push_speed_log? geofence.push_speed_log : false,
      is_disp: geofence.is_disp? geofence.is_disp : false,
      deviceMap: I.initialize(this.props.deviceMap),
      geojson: JSON.parse(geofence.vertexes),
    }
    this.handleSelect = this.handleSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.submit = this.submit.bind(this);
  }

  handleChange(event) {
    this.realtimeCheck(event.target.value);
    if(event.target.id == "name"){
      this.setState({geofence_name: event.target.value});
    }
    else if(event.target.id == "radius"){
      this.setState({
        radius: event.target.value,
        circles: [],
      });
    }
    else if(event.target.id == "color_code"){
      this.setState({
        color_code: event.target.value,
      });
    }
    else if(event.target.id == "push_event_log"){
      this.setState({
        push_event_log: !this.state.push_event_log,
      });
    }
    else if(event.target.id == "push_speed_log"){
      this.setState({
        push_speed_log: !this.state.push_speed_log,
      });
    }
    else if(event.target.id == "geojson"){
      this.setState({
        geojson: event.target.value,
      });
    }
    else if(event.target.id == "is_disp"){
      this.setState({
        is_disp: !this.state.is_disp,
      });
    }
  }

  options(){
    let options = this.props.geofence_types.map((g) =>
      {
        let isSelected = false;
        if(g[1] == this.props.geofence.geofence_type)
          isSelected = true;
        else
          isSelected = false;

        return {label: g[0], value: g[1], isSelected: isSelected};

      });

    return options;
  }

  handleSelect(option) {
    this.setState({
      geofence_type: option.value,
      lines:[],
      circles:[],
      polygons:[],
      vbuoys:[],
      geojsons:[],
    });
  }

  handleClick(option) {
    var vertex = [];
    this.realtimeCheck(this.state.geofence_name);
    // Lines
    if(this.state.geofence_type == 1){
      if(this.state.lines.length > 0 ){
        vertex = this.state.lines[0].vertexes.slice();
      }
      vertex.push(option.latlng);
      this.setState({lines:[{geofence_name: this.state.geofence_name, vertexes: vertex, color_code: this.state.color_code,
                     push_event_log: this.state.push_event_log, push_speed_log: this.state.push_speed_log, 
      }]});
    }
    // Circles
    else if(this.state.geofence_type == 2){
      vertex = [];
      vertex = {radius: parseInt(this.state.radius, 10),
                   center:option.latlng };
      this.setState({circles:[{geofence_name: this.state.geofence_name, vertexes: vertex, color_code: this.state.color_code,
                     push_event_log: this.state.push_event_log, push_speed_log: this.state.push_speed_log, 
      }]});
    }
    // polygons
    else if(this.state.geofence_type == 3){
      if(this.state.polygons.length > 0 ){
        vertex = this.state.polygons[0].vertexes.slice();
      }
      vertex.push(option.latlng);
      this.setState({polygons:[{geofence_name: this.state.geofence_name, vertexes: vertex, color_code: this.state.color_code,
                     push_event_log: this.state.push_event_log, push_speed_log: this.state.push_speed_log, 
      }]});
    }
    // Vbuoys
    else if(this.state.geofence_type == 4){
      vertex = [];
      vertex = {radius: parseInt(this.state.radius, 10),
                   center:option.latlng };
      this.setState({vbuoys:[{geofence_name: this.state.geofence_name, vertexes: vertex, color_code: this.state.color_code,
                     push_event_log: this.state.push_event_log, push_speed_log: this.state.push_speed_log, 
      }]});
    }

    else{
      alert("Geofenceタイプを選択してください");
      return;
    }
  }

  handleReset() {
    this.setState({
      lines:[],
      circles:[],
      polygons:[],
      vbuoys:[],
      geojsons:[],
    });
    console.log("reset");
  }

  handleChangeColor = (color) => {
    this.setState({ color_code: color.hex });
  };



  submit = (e) => {
    e.preventDefault();
    if( this.state.loading ){
      alert("登録処理中です");
      return;
    }

    let param = {};

    //callback success
    let onSuccess = (response) =>{
      this.setState({ loading: false });
      setTimeout(response.data);
    }
    //callback error
    let onError = (error) =>{
      this.setState({ loading: false });
      console.log(error);
      alert("登録に失敗しました");
    }

    //POST
    if(this.checkParam()){
      param.name = this.state.geofence_name;
      param.geofence_type = this.state.geofence_type;
      param.color_code = this.state.color_code;
      param.push_event_log = this.state.push_event_log;
      param.push_speed_log = this.state.push_speed_log;
      param.is_disp = this.state.is_disp;
      param.vertexes = JSON.stringify(this.checkParam());
      this.setState({ loading: true });
      if(this.props.edit)
        API.updateGeofence({ param: param, id: this.props.geofence.id,
                             authenticity_token: this.props.authenticity_token, 
                             onSuccess: onSuccess, onError: onError});
      else
        API.createGeofence({ param: param, organization_id: this.props.organization_id,
                             authenticity_token: this.props.authenticity_token, 
                             onSuccess: onSuccess, onError: onError});
    }
  } 

  realtimeCheck(param){
    if(param.length >= 1){
      this.setState({ submit_status: true});
    }
    else{
      this.setState({ submit_status: false});
    }
  }

  checkParam(){
    // nameのチェック
    if(this.state.geofence_name.length < 1){
      alert("Geofence名を入力してください");
      return false;
    }
    // Lines
    if(this.state.geofence_type == 1){
      //配列2以上
      if(this.state.lines.length > 0 && this.state.lines[0].vertexes.length >= 2 ){
        let payload = {};
        payload = this.state.lines[0].vertexes;
        return payload;
      }
      else{
        alert("最低2点、座標を指定してください");
        return false;
      }
    }
    // Circles
    else if(this.state.geofence_type == 2){
      //座標があり、半径 > 0
      if(this.state.circles.length > 0 && this.state.circles[0].vertexes.center && this.state.circles[0].vertexes.radius > 0){
        let payload = {};
        payload = this.state.circles[0].vertexes;
        return payload;
      }
      else{
        alert("半径、中心座標を指定してください");
        return false;
      }
    }
    // polygons
    else if(this.state.geofence_type == 3){
      //配列3以上
      if(this.state.polygons.length > 0 && this.state.polygons[0].vertexes.length >= 3 ){
        let payload = {};
        payload = this.state.polygons[0].vertexes;
        return payload;
      }
      else{
        alert("最低3点、座標を指定してください");
        return false;
      }
    }
    // vbuoys
    else if(this.state.geofence_type == 4){
      //座標があり、半径 > 0
      if(this.state.vbuoys.length > 0 && this.state.vbuoys[0].vertexes.center && this.state.vbuoys[0].vertexes.radius > 0){
        let payload = {};
        payload = this.state.vbuoys[0].vertexes;
        return payload;
      }
      else{
        alert("半径、中心座標を指定してください");
        return false;
      }
    }
    // Geojsons
    else if(this.state.geofence_type == 5){
			try {
				JSON.parse(this.state.geojson);
			} catch (error) {
        alert("Geojsonを入力してください");
				return false;
			}
			return this.state.geojson;
    }

    else{
      alert("Geofenceタイプを選択してください");
      return false;
    }
  }

  render () {

    return (
      <div>
        <div className="col-md-4 col-xs-12">
          <form onSubmit={this.handleSubmit}>
            <div className="field">
              <label>Geofence名</label>
              <input id="name" className="form-control" value={this.state.geofence_name} type="text" onChange={this.handleChange} />
            </div>

            <div className="field">
              <label>Geofenceタイプを選択</label>
              <Select options={this.options()} onChange={this.handleSelect} defaultValue={this.options()[this.props.geofence.geofence_type-1]} />
            </div>

            { (this.state.geofence_type == 2 || this.state.geofence_type == 4) &&
              <div className="field">
                <label>半径[m]</label>
                <input id="radius" className="form-control" value={this.state.radius} type="number" onChange={this.handleChange} />
              </div>
            }

            { (this.state.geofence_type == 5 ) &&
              <div className="field">
                <label>表示専用：Geojson</label>
                <textarea id="geojson" className="form-control" value={this.state.geojson} onChange={this.handleChange} />
              </div>
            }

            <div className="field">
              <label>カラーコード</label>
              <input id="color_code" className="form-control" value={this.state.color_code} type="text" onChange={this.handleChange} />
              <SketchPicker
                color={ this.state.color_code }
                onChangeComplete={ this.handleChangeColor }
                />
            </div>

            <div className="field">
              <label>イベントログ(入退出）の記録する</label>
              <div className={"switch"}>
                <label className={"switch__label"}>
                  <input id="push_event_log" type="checkbox" className={"switch__input"} checked={this.state.push_event_log} onChange={this.handleChange}/>
                  <span className={"switch__content"}></span>
                  <span className={"switch__circle"}></span>
                </label>
              </div>
            </div>
            <div className="field">
              <label>アラートの記録を除外する</label>
              <div className={"switch"}>
                <label className={"switch__label"}>
                  <input id="push_speed_log" type="checkbox" className={"switch__input"} checked={this.state.push_speed_log} onChange={this.handleChange}/>
                  <span className={"switch__content"}></span>
                  <span className={"switch__circle"}></span>
                </label>
              </div>
            </div>
            <div className="field">
              <label>ジオフェンスを表示する</label>
              <div className={"switch"}>
                <label className={"switch__label"}>
                  <input id="is_disp" type="checkbox" className={"switch__input"} checked={this.state.is_disp} onChange={this.handleChange}/>
                  <span className={"switch__content"}></span>
                  <span className={"switch__circle"}></span>
                </label>
              </div>
            </div>


            <div className="field">
              <label>指定座標</label>
            </div>

            <div className="field">
              <Button variant="primary" onClick={this.submit} disabled={!this.state.submit_status}>　登録　</Button>{'  '}
              <Button variant="secondary" onClick={this.handleReset} >Reset </Button>
            </div>
         </form>
       
        </div>
        <div className="col-md-8 col-xs-12">
          <MapBrowser center={this.props.center} zoom={16} handleClick={this.handleClick}
                      deviceMap={this.state.deviceMap}
                      lines={this.props.lines}
                      circles={this.props.circles}
                      polygons={this.props.polygons}
                      vbuoys={this.props.vbuoys}
                      geojsons={this.props.geojsons}
                      map_ws_endpoint={this.props.map_ws_endpoint}
                      organization_id={this.props.organization_id}

                      formlines={this.state.lines}
                      formcircles={this.state.circles}
                      formpolygons={this.state.polygons}
                      formvbuoys={this.state.vbuoys}

                      showPins={true}
          />
        </div>
      </div>
    );
  }
}
