// Common compare
function comp(a, b){
  let comparison = 0;
  if (a > b) {
    comparison = 1;
  } else if (a < b) {
    comparison = -1;
  }
  return comparison;
}

export default class Sort {
  // Time
  static compareTimeAsc(a, b) {
    const strA = a.created_at;
    const strB = b.created_at;
    return comp(strA, strB);
  }
  static compareTimeDesc(a, b) {
    const strA = a.created_at;
    const strB = b.created_at;
    return comp(strB, strA);
  }

  // Device
  static compareDeviceAsc(a, b) {
    const strA = a.device_id;
    const strB = b.device_id;
    return comp(strA, strB);
  }
  static compareDeviceDesc(a, b) {
    const strA = a.device_id;
    const strB = b.device_id;
    return comp(strB, strA);
  }

  //Athlete
  static compareAthleteAsc(a, b) {
    let strA = a.athlete;
    let strB = b.athlete;
    if (a.athlete)
      strA = a.athlete.toUpperCase();
    if (b.athlete)
      strB = b.athlete.toUpperCase();
    return comp(strA, strB);
  }
  static compareAthleteDesc(a, b) {
    let strA = a.athlete.toUpperCase();
    let strB = b.athlete.toUpperCase();
    if (a.athlete)
      strA = a.athlete.toUpperCase();
    if (b.athlete)
      strB = b.athlete.toUpperCase();
    return comp(strB, strA);
  }

  // MsgType
  static compareMsgTypeAsc(a, b) {
    const strA = a.msg_type;
    const strB = b.msg_type;
    return comp(strA, strB);
  }
  static compareMsgTypeDesc(a, b) {
    const strA = a.msg_type;
    const strB = b.msg_type;
    return comp(strB, strA);
  }

  // Msg
  static compareMsgAsc(a, b) {
    const strA = a.msg;
    const strB = b.msg;
    return comp(strA, strB);
  }
  static compareMsgDesc(a, b) {
    const strA = a.msg;
    const strB = b.msg;
    return comp(strB, strA);
  }

  // Geofence
  static compareGeofenceAsc(a, b) {
    const strA = a.geofence;
    const strB = b.geofence;
    return comp(strA, strB);
  }
  static compareGeofenceDesc(a, b) {
    const strA = a.geofence;
    const strB = b.geofence;
    return comp(strB, strA);
  }

}
