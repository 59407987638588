import React, {Component} from "react";
import PagiList from "../list/PagiList";
import Sort from "./EventLogCompairFunctions";

import { Button } from 'react-bootstrap'

const columuns = [
  {dataField:'created_at',text:'Time', sort:true, sortOrder:'desc', afunc: Sort.compareTimeAsc, dfunc: Sort.compareTimeDesc},
  {dataField:'device_id',text:'Device ID', sort:true, afunc: Sort.compareDeviceAsc, dfunc: Sort.compareDeviceDesc},
  {dataField:'athlete',text:'User', sort:true, afunc: Sort.compareAthleteAsc, dfunc: Sort.compareAthleteDesc},
  {dataField:'msg_type',text:'Event', sort:true, afunc: Sort.compareMsgTypeAsc, dfunc: Sort.compareMsgTypeDesc},
  {dataField:'geofence',text:'Geofence', sort:true, afunc: Sort.compareGeofenceAsc, dfunc: Sort.compareGeofenceDesc},
  {dataField:'msg',text:'Detail', sort:true, afunc: Sort.compareMsgAsc, dfunc: Sort.compareMsgDesc},

]; 

class EventLog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      logs: null,
    }
  }

  componentDidMount() {
    //表示データ用加工
    let logs = this.props.logs;
    let out = []
    for(let i in logs){
      let log = {
        id:         logs[i].id,
        created_at: logs[i].created_at,
        device_id:  logs[i].device_id,
        athlete:    logs[i].athlete,
        geofence:   logs[i].geofence,
        msg_type:   logs[i].msg_type,
        msg:        logs[i].msg,
        searchstr:  logs[i].created_at + logs[i].device_id + logs[i].msg_type + logs[i].msg
      }
      out.push(log);
    }
    this.setState({ logs: out });
  }
 
  render() {
    if(!this.state.logs){
      return <p>Loading...</p>;
    }

    return (
      <span>
        <PagiList
          columuns={ columuns }
          data={ this.state.logs }
          itemsPerPage={100}
          options={{
                searchBox: true,
                multipleSelect: false,
                expandable: false,
                actionsColumn: false,
                pagination: true
          }}
          onSort={this.onSort}
        />
      </span>

    );
  }
}

export default EventLog;
