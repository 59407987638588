import React, {Component} from "react";
//import Moment from 'moment';
//import 'font-awesome/css/font-awesome.min.css';
//import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import '!style-loader!css-loader!./CustomizeField.css';


export const StatusField = ({ columnKey, row, onColumnClick }) => {

  let status = <span className="offline">OFFLINE</span>;
  //let status = <BootstrapSwitchButton checked={false} onstyle="success" size="sm" />;

  if(row.status){
    status = <span className="online">ONLINE<div className="last">{row.status}</div></span>;
    //status = <BootstrapSwitchButton checked={true} onstyle="success" size="xs" />;
  }

  return (
    <span>{status}</span>
  );
}
