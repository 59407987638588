import React from "react"
import PropTypes from "prop-types"
import MapBrowser from "../map/MapBrowser"
import API from "../API"
import I from "./Initialize"
import Select from 'react-select';
import { ListGroup, Button, Form } from 'react-bootstrap'
import { Card, CardHeader, CardBody } from 'reactstrap';
import DeviceList from '../device_list/DeviceList'
import { mapType } from '../map/MapBrowser'
import EmergencyNotice from './EmergencyNotice'
import {initializeWebsocket, cleanWebsocket} from './Websocket.js';

var this_obj;
let listChange = 0;
export default class GeofenceForm extends React.Component {

  constructor(props) {
    super(props);
    let original = I.initialize(this.props.deviceMap)

    //Initialize device group visible
    let deviceGroups = [];
    let noGroup = {id: -1, group_name: "No Group", visible: true, count: this.props.deviceGroupsCount[""]};
    deviceGroups.push(noGroup);
    for(let i in this.props.deviceGroups){
      let dg = this.props.deviceGroups[i];
      dg.visible = true;

      if(this.props.deviceGroupsCount[dg.id]){
        dg.count = this.props.deviceGroupsCount[dg.id];
      }else{
        dg.count = 0;
      }
      deviceGroups.push(dg);
    }

    this.state = {
      deviceMap: original,
      originalMap: original,
      show_label: true,
      show_line: true,
      knot: false,
      max_line_length: 30,
      measure_distance: false,
      deviceGroups: deviceGroups,
      mapType: mapType.osmBlackAndWhite,
      online: false,
      emergencies: [],//this.props.emergencies,
      emergencies_count : this.props.emergencies.length,
      notice: false,
    }
    this_obj = this;
    this.MapBrowserRef = React.createRef();
    this.checkDevicesInfo = this.checkDevicesInfo.bind(this);
    this.deviceMove = this.deviceMove.bind(this);
    this.checkDevicesStatus = this.checkDevicesStatus.bind(this);
    this.handleShowLabel = this.handleShowLabel.bind(this);
    this.handleShowLine = this.handleShowLine.bind(this);
    this.handleChangeKnot = this.handleChangeKnot.bind(this);
    this.handleMeasureDistance = this.handleMeasureDistance.bind(this);
    this.handleUpdateDeviceMap = this.handleUpdateDeviceMap.bind(this);
    this.toggleMeasureDistance = this.toggleMeasureDistance.bind(this);
    this.setMapType = this.setMapType.bind(this);
    this.cleanup = this.cleanup.bind(this);
    this.intervalIdDeviceInfo = null;
    this.intervalIdDeviceStatus = null;
    this.intervalIdDeviceMove = null;
    this.intervalEmergencies = null;
  }

  componentDidMount() {

    initializeWebsocket(this.state.deviceMap, this, this.props.map_ws_endpoint, this.props.organization_id, this.props.authenticity_token);
    //this.checkDevicesInfo();
    //this.checkDevicesStatus();
    this.deviceMove();
    this.checkEmergencies();

    // railsによるMPAの場合、ページ遷移時にcomponentWillUnmountが呼ばれないので、
    // turbolinks:before-visitイベントでクリーンアップするようにした。
    // その他の解決策: SPAにする、関数コンポーネントにしてuseEffectを使うなど。
    document.addEventListener("turbolinks:before-visit", this.cleanup);
  }

  componentWillUnmount(){
    this.cleanup();
  }

  cleanup(){
    cleanWebsocket();
    clearInterval(this.intervalIdDeviceInfo);
    clearInterval(this.intervalIdDeviceStatus);
    clearInterval(this.intervalIdDeviceMove);
    clearInterval(this.intervalEmergencies);
    document.removeEventListener("turbolinks:before-visit", this.cleanup);
  }


  //デバイスを動かす
  deviceMove(){
    this.intervalIdDeviceMove =  setInterval(() => {
      this.setState({ deviceMap: this.state.deviceMap.slice() });
        } ,1000);
  }


  //デバイスの状態
  checkDevicesInfo(){
    let onSuccess = (res) => {
      let devices_info = res.data.devices;
      let deviceMap = this.state.deviceMap;
      for(let i in deviceMap){
        if( devices_info[deviceMap[i].deviceId] ){
          let lastgps = new Date(Date.parse(devices_info[deviceMap[i].deviceId].lastGPS));
          deviceMap[i].status = lastgps.getHours()+":"+lastgps.getMinutes()+":"+lastgps.getSeconds()
        }
        else{
          deviceMap[i].status = "";
        }
      }
    }
    //デバイスステータスを取得
    API.getDeviceInfo({ onSuccess: onSuccess, organization_id: this.props.organization_id })
    this.intervalIdDeviceInfo =  setInterval(() => {
        API.getDeviceInfo({ onSuccess: onSuccess, organization_id: this.props.organization_id })
        } ,10000);
  }

  //デバイスの状態
  checkDevicesStatus(){
    let onSuccess = (res) => {
      let device_statuses = res.data;

      let device;
      let deviceMap = this_obj.state.deviceMap;
      for(let i in deviceMap){
        for(let j in device_statuses){
          if(deviceMap[i].deviceId == device_statuses[j].serial){
            //デバイスのプロパティをアップデート
            device = deviceMap[i]
            device.geofence = device_statuses[j].geofence_name;
            device.searchstr = device.ori_searchstr + device.geofence;
            if(device.status)
              device.searchstr = device.searchstr + "ONLINE";
            device.emergency = device_statuses[j].emergency;
            device.emergency_signaled_at = device_statuses[j].emergency_signaled_at;
            break;
          }
        }
      }

      // Statusの値を持つDeviceを前にソートする
      let sorted_deviceMap = [];
      let n_gps_device = 0;
      for(let i in deviceMap){
        let device = deviceMap[i];
        if(device.status){
          sorted_deviceMap.splice( n_gps_device, 0, device );
          n_gps_device++;
        } else {
          sorted_deviceMap.push(device);
        }
      }

      // DeviceMapのidの順番を比較して、変化があった場合listChangeをインクリメント
      let pre_idList = deviceMap.map(function(item){
        return item.id;
      });
      let new_idList = sorted_deviceMap.map(function(item){
        return item.id;
      });

      if(pre_idList.toString() != new_idList.toString()){
        listChange++;
      }
      // ソートした配列をdeviceMapに代入
      deviceMap = sorted_deviceMap;

      //device setStateをアップデート
      this_obj.setState({
        deviceMap: deviceMap
      });
    }
    //デバイスステータスを取得
    API.getDeviceStatus({organization_id: this.props.organization_id, onSuccess: onSuccess})
    this.intervalIdDeviceStatus =  setInterval(() => {
        API.getDeviceStatus({organization_id: this.props.organization_id, onSuccess: onSuccess})
        } ,5000);
  }

  //緊急情報の取得
  checkEmergencies(){
    let onSuccess = (res) => {

			if(res.data.length > this.state.emergencies_count){
				alert("新たな緊急通報者がいます！")
				this.setState({
					notice : false,
					emergencies: res.data,
					emergencies_count: res.data.length
				});
				return;
			}
			if( res.data.length > 0){
				this.setState({
					notice : true,
					emergencies: res.data,
					emergencies_count: res.data.length
				});
			} else {
				this.setState({
					notice : false,
					emergencies: res.data,
					emergencies_count: res.data.length
				});
			}
    }
    let onError = (res) => {
			console.log("--API.getEmergencies err--", res.data)
    }
    //デバイスステータスを取得
    this.intervalEmergencies =  setInterval(() => {
      API.getEmergencies({ onSuccess: onSuccess, onError: onError, organization_id: this.props.organization_id, authenticity_token: this.props.authenticity_token })
    } ,3000);
		return ()=> clearInterval(this.intervalEmergencies)
  }


  handleShowLabel(){
    this.setState({
      show_label: !this.state.show_label,
    });
  }

  handleShowLine(){
    this.setState({
      show_line: !this.state.show_line,
    });
  }

  handleChangeKnot(){
    this.setState({
      knot: !this.state.knot,
    });
  }

  handleMeasureDistance(){
    this.toggleMeasureDistance();
    this.MapBrowserRef.current.initShowDistanceDevices();
  }

  toggleMeasureDistance(){
    this.setState({
      measure_distance: !this.state.measure_distance,
    });
  }

  //子コンポーネントから呼び出される更新関数
  handleUpdateDeviceMap( newDeviceGroups, newMap){
    this.setState({
        deviceGroups: newDeviceGroups,
        deviceMap: newMap,
    });
  }

  //子コンポーネントから呼び出される更新関数（max_line_length)
  handleChangeMaxLength(val){
    this_obj.setState({
      max_line_length: val,
    })
  }

  setMapType(type){
    this.setState({
      mapType: type,
    });
  }

  render () {
    const {deviceMap, originalMap} = this.state;
    // listChangeが変化するとDeviceListは強制的に更新される
    const deviceList =  <DeviceList deviceMap={deviceMap}
                                    originalMap={originalMap}
                                    deviceGroups={this.state.deviceGroups}
                                    handleUpdateDeviceMap={this.handleUpdateDeviceMap}
                                    key={listChange}
                                    handleShowLabel={this.handleShowLabel}
                                    handleShowLine={this.handleShowLine}
                                    show_label={this.state.show_label}
                                    show_line={this.state.show_line}
                                    knot={this.state.knot}
                                    handleChangeKnot={this.handleChangeKnot}
                                    measure_distance={this.state.measure_distance}
                                    max_line_length={this.state.max_line_length}
                                    handleChangeMaxLength={this.handleChangeMaxLength}
                                    handleMeasureDistance={this.handleMeasureDistance}
                                    toggleMeasureDistance={this.toggleMeasureDistance}
                                    setMapType={this.setMapType}
                                    />

    return (
      <>
					<EmergencyNotice notice={this.state.notice} />
          <MapBrowser ref={this.MapBrowserRef}
                      center={this.props.center} zoom={this.props.zoom} height={'95vh'}
                      lines={this.props.lines}
                      circles={this.props.circles}
                      polygons={this.props.polygons}
                      vbuoys={this.props.vbuoys}
                      geojsons={this.props.geojsons}
                      emergencies={this.state.emergencies}
                      deviceMap={this.state.deviceMap}
                      showPins={false}
                      topright={deviceList}
                      show_label={this.state.show_label}
                      show_line={this.state.show_line}
                      max_line_length={this.state.max_line_length}
                      measure_distance={this.state.measure_distance}
                      mapType={this.state.mapType}
                      online={this.state.online}
                      knot={this.state.knot}
          />
      </>
    );
  }
}
