import React, {Component} from "react";
import { Badge } from 'react-bootstrap';
//import Moment from 'moment';
//import 'font-awesome/css/font-awesome.min.css';
//import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import '!style-loader!css-loader!./CustomizeField.css';

//デバイスステータス用
export const StatusField = ({ columnKey, row, onColumnClick }) => {
  let status = <span className="offline">OFFLINE</span>;
  if(row.status){
    status = <span className="online">ONLINE</span>;
  }
  return (
    <span>{status}</span>
  );
}

//GPS用
export const GpsField = ({ columnKey, row, onColumnClick }) => {
  let gps = "";
  if(row.fix == 1){
    gps = (
            <div>
              <span>{row.gps}</span>
              <Badge pill variant="success" className="fix">Fix</Badge>
            </div>
          );
  }else if(row.fix == 2){
    gps = (
            <div>
              <span>{row.gps}</span>
              <Badge pill variant="success" className="fix">D-Fix</Badge>
            </div>
          );
  }else{
    gps = row.gps;
  }
  return (
    <span>{gps}</span>
  );
}


//グループ用
export const GroupField = ({ columnKey, row, onColumnClick }) => {
  let group;
  if(row.group_name_and_color){
    let g = row.group_name_and_color.split(',');
    const gStyle = {
      color: g[1],
      textShadowRadius: 1,
      textShadowColor: 'rgba(0, 0, 0, 0)',
    }
    group = <span>{g[0]}<i className="fas fa-circle" style={gStyle}></i></span>
  }

  return (
    <span>{group}</span>
  );
}

//競技用
export const InuseField = ({ columnKey, row, onColumnClick }) => {
  let inuse = row.in_use
  let out
  if(inuse)
    out = <a className={'btn btn-success'} onClick={row.inuse_func} value={true} id={row.id}>使用中</a>
  else
    out = '-'
  return (
    <span>{out}</span>
  );
}


//貸出用
export const LoanField = ({ columnKey, row, onColumnClick }) => {
  let loan = row.is_loan
  let out;
  if(loan)
    out = <a className={'btn btn-success'} onClick={row.loan_func} value={true} id={row.id}>貸出中</a>
  else
    out = <a className={'btn btn-light'} onClick={row.loan_func} value={false} id={row.id}>返却済</a>
  return (
    <span>{out}</span>
  );
}

