import * as React from 'react';
import './online.css';

function Online(props) {

  const {online} = props;

  return (
    online ? (
      <p className="leaflet-bottom leaflet-right online"> ONLINE </p>
    ) : (
      <p className="leaflet-bottom leaflet-right offline"> OFFLINE </p>
    )
  );
}

export default React.memo(Online);
