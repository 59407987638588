import axios from 'axios';

export default class API {

  // Create Geofence
  static createGeofence({param, organization_id, authenticity_token, onSuccess, onError}){
    axios.post(`/organizations/${organization_id}/geofences.json`, {geofence: param},
               {headers: {'Content-Type': 'application/json', 'X-CSRF-Token': `${authenticity_token}`}})
        .then((response) => {
          onSuccess(response);
        })
        .catch((error) => {
          onError(error);
        });
  }

  // Update Geofence
  static updateGeofence({param, id, authenticity_token, onSuccess, onError}){
    axios.patch(`/geofences/${id}.json`, {geofence: param},
               {headers: {'Content-Type': 'application/json', 'X-CSRF-Token': `${authenticity_token}`}})
        .then((response) => {
          onSuccess(response);
        })
        .catch((error) => {
          onError(error);
        });
  }


  // Get ReplayJsonData
  static getReplayJson(url, onSuccess, onError){
    axios.get(url)
        .then((response) => {
          onSuccess(response);
        })
        .catch((error) => {
          onError(error);
        });
   }

  // Get Device Info
  static getDeviceInfo({onSuccess, organization_id}){
    let onError = (error) => {
      console.log(error);
    }

    axios.get(`/api/devices/`+organization_id, {headers: { 'Content-Type': 'application/json' }} )
        .then((response) => {
          onSuccess(response);
        })
        .catch((error) => {
          onError(error);
        });
  }

  // Get geofence status
  static getDeviceStatus({ onSuccess, organization_id}){
    let onError = (error) => {
      console.log(error);
    }

    axios.get(`/api/device_status?organization_id=`+organization_id, {headers: { 'Content-Type': 'application/json' }} )
        .then((response) => {
          onSuccess(response);
        })
        .catch((error) => {
          onError(error);
        });
  }

  // Change Loan status
  static changeLoanStatus({id, authenticity_token, onSuccess, onError}){
    axios.post(`/sensors/${id}/change_loan_status`, {},
               {headers: {'Content-Type': 'application/json', 'X-CSRF-Token': `${authenticity_token}`}})
        .then((response) => {
          onSuccess(response);
        })
        .catch((error) => {
          onError(error);
        });
  }

  // Change Inuse status
  static changeInuseStatus({id, authenticity_token, onSuccess, onError}){
    axios.post(`/sensors/${id}/change_inuse_status`, {},
               {headers: {'Content-Type': 'application/json', 'X-CSRF-Token': `${authenticity_token}`}})
        .then((response) => {
          onSuccess(response);
        })
        .catch((error) => {
          onError(error);
        });
  }

  // Get Emergencies
  static getEmergencies({id, authenticity_token, organization_id, onSuccess, onError}){
    axios.get(`/api/${organization_id}/today_emergencies`, {headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${authenticity_token}` }} )
        .then((response) => {
          onSuccess(response);
        })
        .catch((error) => {
          onError(error);
        });
  }

}
