import React from "react"

var websocket;
let updWebsocketTime = null;
var intervalWSCheck = null;
var intervalReconnect = null;

var init_count=0;
var err_count=0;

export function cleanWebsocket(){
	clearInterval(intervalReconnect);
	clearInterval(intervalWSCheck);
	websocket.onopen = null;
	websocket.onclose = null;
	websocket.onmessage = null;
	websocket.close();
	console.log("ws closed.");
}


export function initializeWebsocket(deviceMap, this_obj, map_ws_endpoint, organization_id, authenticity_token){
	init_count = init_count+1;

	if(init_count > 1){
		console.log("initialize always running.:"+ init_count)
	}

	updWebsocketTime = new Date();
	let websocketURL = map_ws_endpoint + organization_id;
	console.log("[WS Endpoint] "+websocketURL)
	websocket = new WebSocket(websocketURL);

	//Websocketデータの受信をチェック
	let checkWebsocketOnline =() => {
		let now_t = new Date();
		console.log(now_t-updWebsocketTime)
		if( now_t-updWebsocketTime > 120000 ) {
			clearInterval(intervalWSCheck);
			intervalWSCheck = null;
			websocket.onopen = null;
			websocket.onmessage = null;
			websocket.close();
			console.log("ws closed.[time check]");
			init_count = init_count-1;
			this_obj.setState({
				online: false,
			});

			if(err_count < 3){
				err_count = err_count+1;
				console.log("Can not recieve data. Connection close. 再接続します。:"+err_count)
				initializeWebsocket(deviceMap, this_obj, map_ws_endpoint, organization_id, authenticity_token);
			}
		}
	}


	websocket.onopen = (event) => {
		console.log("ws opened.");
		this_obj.setState({
			online: true,
		});

		//Tokenを送信し、データ受信を行う
		websocket.send(authenticity_token)

		//確立したら状態を確認する
		if( websocket.readyState != 1 ){
			this_obj.setState({
				online: false,
			});
			console.log("コネクションが切断(2)。3秒後に再接続します。:", websocket.readyState)
			intervalReconnect = setTimeout(() => {
				initializeWebsocket(deviceMap, this_obj, map_ws_endpoint, organization_id, authenticity_token);
			}, 3000);
			return;
		}

		//WSのデータ受信状態をチェック
		if(!intervalWSCheck){//２周動作チェック
			intervalWSCheck = setInterval(() => {
				checkWebsocketOnline();
			}, 5000);
			return () => clearInterval(intervalWSCheck);
		}else{
			console.log("Check interval already running")
		}

	}


	websocket.onclose = (event) => {
		init_count = init_count-1;
		this_obj.setState({
			online: false,
		});
		console.log("コネクションが切断(1)。3秒後に再接続します。")
		if(err_count < 3){
			err_count = err_count+1;
			intervalReconnect = setTimeout(() => {
				initializeWebsocket(deviceMap, this_obj, map_ws_endpoint, organization_id, authenticity_token);
			}, 3000);
		}
	}

	websocket.onmessage = function (event) {
		err_count = 0;
		updWebsocketTime = new Date();//online確認用
		let data = JSON.parse(event.data);
		console.log(data);

		if(data.type != "gps")
			return

		let device;
		let deviceMap = this_obj.state.deviceMap;

		for(let i in deviceMap){
			if(deviceMap[i].deviceId == data.id){
				//デバイスのプロパティをアップデート
				device = deviceMap[i]
				break;
			}
		}

		if (device != null){
			//デバイスを移動
			let d = new Date();
			let t = data.time;
			d.setHours(t.slice(0, 2));
			d.setMinutes(t.slice(2, 4));
			d.setSeconds(t.slice(4, 9));
			d.setTime(d.getTime() + 1000*60*60*9);//9時間追加している？！　他にやり方があるので要修正

			device.emergency_signaled_at = data.emergency_signaled_at;
			device.emergency_reason = data.emergency_reason;
			device.emergency = data.emergency;

			//mt fuji 500
			if(new Date() - d > 1000*60*5){
				device.emergency = true
				device.emergency_signaled_at = d;
				device.emergency_reason = "オフライン";
			}

			//GPSを受信しているときのみ動かす
			if(data.lat > 0.0){
				device.ws_move(data.lat, data.long, data.spd, d.toLocaleTimeString());
			}
		}
	}
}


