/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { default as BaseSlider } from 'rc-slider';

// import 'rc-slider/assets/index.css';
// ↑のコードではheroku上で認識されない。↓でstyle-loaderを用いたimportに変更
import '!style-loader!css-loader!rc-slider/assets/index.css'; 

// Slider用 Rangeコンポーネント
const createSliderWithTooltip = BaseSlider.createSliderWithTooltip;
const Range = createSliderWithTooltip(BaseSlider.Range);

// デフォルトのスライダーのハンドルを操作した際に表示されるツールチップの値
const DEFAULT_TOOLTIP_FORMATTER = value => value;

/**
 * グラフデータ用スライダー
 */
export default class Slider extends Component {

  static propTypes = {
    dataRange: PropTypes.object,
    selectionRange: PropTypes.object,
    handleSelectDataRange: PropTypes.func,
    customTipFormatter: PropTypes.func,
  }

  render() {
    return (
      <Range
        min={this.props.dataRange.min}
        max={this.props.dataRange.max}
        step={this.props.dataRange.step}
        value={this.props.values}
        pushable={true}
        onChange={this.props.handleSelectDataRange}
        tipFormatter={this.props.customTipFormatter || DEFAULT_TOOLTIP_FORMATTER}
      />
    );
  }
}
