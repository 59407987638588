import Device from "../device/Device"

export default class Initialize {

  static initialize(devices){
    //デバイス一覧の作成
    let deviceMap = 
    devices.map(function(d, i) {
      //deviceMap[d.id] = new Device(i, d);
      return new Device(i, d)
    });

    return deviceMap;
  }
}
