import React, { Component } from 'react';
import { Polyline } from 'react-leaflet';

const startColor = '#FFFFFF';
const goalColor = '#FF0000';


export default class BuoyLines extends Component {
  constructor(props){
    super(props);
  }
  
  componentDidMount(){
  }

   
  render() {
    const { devices } = this.props;

    let s_buoy, s_boat, g_buoy, g_boat;
    let start_line, goal_line;

    for(let d of devices){
      switch(d.category){
        case 3:
          if(d.name == "S")
            s_buoy = d
          if(d.name == "G")
            g_buoy = d
          break;
        case 4:
          if(d.name == "S")
            s_boat = d
          if(d.name == "G")
            g_boat = d
          break;
        }
    }


    if((s_buoy) && (s_boat)){
      start_line = <Polyline color={startColor}   positions={[s_buoy.pos, s_boat.pos]} />
    }

    if((g_buoy) && (g_boat)){
      goal_line = <Polyline color={goalColor}   positions={[g_buoy.pos, g_boat.pos]} />
    }

    return (
      <span>
        {start_line}
        {goal_line}
      </span>
    );
  }
}
