import L from 'leaflet';
import { Popup } from 'leaflet';
import React, { Component } from 'react';
import { Marker, Polyline } from 'react-leaflet';
import '!style-loader!css-loader!./MeasureDistance.css'; 

const lineColor = '#FFFFFF';

function geoDirection(lat1, lng1, lat2, lng2) {
  // 緯度経度 lat1, lng1 の点を出発として、緯度経度 lat2, lng2 への方位
  // 北を０度で右回りの角度０～３６０度
  var Y = Math.cos(lng2 * Math.PI / 180) * Math.sin(lat2 * Math.PI / 180 - lat1 * Math.PI / 180);
  var X = Math.cos(lng1 * Math.PI / 180) * Math.sin(lng2 * Math.PI / 180) - Math.sin(lng1 * Math.PI / 180) * Math.cos(lng2 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180 - lat1 * Math.PI / 180);
  var dirE0 = 180 * Math.atan2(Y, X) / Math.PI; // 東向きが０度の方向
  if (dirE0 < 0) {
    dirE0 = dirE0 + 360; //0～360 にする。
  }
  var dirN0 = (dirE0 + 90) % 360; //(dirE0+90)÷360の余りを出力 北向きが０度の方向
  return dirN0;
}

export default class MeasureDistance extends Component {
  componentDidMount(){
  }

  //   メートルで距離を算出
  calc_distance(pos1, pos2) {
      let lat1 = pos1[0];
      let lng1 = pos1[1];
      let lat2 = pos2[0];
      let lng2 = pos2[1];
      lat1 *= Math.PI / 180;
      lng1 *= Math.PI / 180;
      lat2 *= Math.PI / 180;
      lng2 *= Math.PI / 180
    return 6371 * Math.acos(Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1) + Math.sin(lat1) * Math.sin(lat2)) * 1000
  }

  getMarkerPositon(pos1, pos2) {
      return [(pos1[0]+pos2[0])/2, (pos1[1]+pos2[1])/2]
  }

  render() {
    let {devices} = this.props;
    let lines = [];
    let distances = [];

    if(devices.length >= 2){
        for(let i=0; i<devices.length-1; i++){

            lines.push(<Polyline color={lineColor} positions={[devices[i].pos, devices[i+1].pos]} key={i}/>);
            let distance  = this.calc_distance(devices[i].pos, devices[i+1].pos);
            let direction = geoDirection(devices[i].pos[0], devices[i].pos[1], devices[i+1].pos[0], devices[i+1].pos[1]);
            let distance_icon = L.divIcon({
                                    html: `${Math.round(distance)} m / ${Math.round(direction)}°`,
                                    className: 'distance_icon',
                                    iconSize: [80,16],
                                });

            distances.push(<Marker
                            key={i}
                            position={this.getMarkerPositon(devices[i].pos, devices[i+1].pos)}
                            icon={distance_icon}/>);
        }
    }

    return (
      <span>
        {this.props.isshow && lines.map((line) => line)}
        {this.props.isshow && distances.map((distance) => distance)}
      </span>
    );
  }
}
