import React, { Component } from 'react';
import MainPlayer from './MainPlayer';
import Device from '../device/Device';


export default class VirtualBuoy extends Component {
  constructor(props){
    super(props);

    var id  = 'virtual_' + this.props.id;
    var tmp = {
      id       : id,
      deviceId : id,
      name     : this.props.name,
      pos      : this.props.center,
      in_use   : false,
      category : 3,
      gpses    : [],
    }

    var device = new Device( id, tmp )
    device.pos = [tmp.pos.lat, tmp.pos.lng]
    this.state = {
      device: device,
    };
  }

  render() {
    return(
      <MainPlayer key={Math.random()}
                  device={this.state.device}
                  show_label={this.props.show_label}
                  measure_distance={this.props.measure_distance}
                  handleShowDistanceDevices={this.props.handleShowDistanceDevices}
      />
    )
  }
}
