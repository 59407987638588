import sup from '../images/sup.svg';
import sail from '../images/sail.png';
import buoy from '../images/buoy.png';
import boat from '../images/boat.png';
import wind from '../images/wind.png';
import drone from '../images/drone.svg';
import car from   '../images/car.svg';
import camera from '../images/camera.svg';
export default {
    sup, sail, buoy, boat, wind, drone, car, camera
}
