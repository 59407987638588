import React, {useEffect, useState} from 'react';
//import './EmergencyNotice.css';

function EmergencyNotice(props) { 
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(props.notice);
  }, [props.notice]);

	let handleClickButton = () =>{
		setShow(false);
	}

		if(show){
		return (
			<div class="alert alert-danger d-flex align-items-center" role="alert">
				<h4>
					緊急通報者がいます！
				<button onClick={handleClickButton}>閉じる</button>
				</h4>
			</div>
		);
		}else{
			return <></>;
		}
}
export default React.memo(EmergencyNotice);
