import React from 'react';
import { Button, Select, MenuItem, InputLabel, Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReplayIcon from '@mui/icons-material/PlayCircle';
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import GroupsIcon from '@mui/icons-material/Groups';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import TerrainIcon from '@mui/icons-material/Terrain';
import TimerIcon from '@mui/icons-material/Timer';

const DisciplineSelect = ({ disciplines }) => {
  const [selectedDiscipline, setSelectedDiscipline] = React.useState('discipline_0');
  const handleChange = (event) => {
    setSelectedDiscipline(event.target.value);
  };

  return (
    <Box sx={{ marginBottom: '20px' }}>
      <InputLabel id="discipline-select-label">種目を選択</InputLabel>
      <Select
        labelId="discipline-select-label"
        id="disciplineSelect"
        value={selectedDiscipline}
        onChange={handleChange}
        sx={{ width: '200px' }}
      >
        {disciplines.map((discipline, index) => (
          <MenuItem key={index} value={`discipline_${index}`}>
            {discipline.name}
          </MenuItem>
        ))}
      </Select>

      <Box id="races">
        {disciplines.map((discipline, disciplineIndex) => (
          <Box
            key={disciplineIndex}
            id={`discipline_${disciplineIndex}`}
            sx={{ display: selectedDiscipline === `discipline_${disciplineIndex}` ? 'block' : 'none' }}
          >
            {discipline.races
              .filter((race) =>
              (discipline.show_hidden !== null ? discipline.show_hidden === "1" : race.public) &&
                !race.soft_destroyed
              )
              .sort((a, b) => a.display_order - b.display_order)
              .map((race, raceIndex) => (
                (discipline.races.length === 1 || discipline.races.filter(race => race.public !== false).length === 1) ? (
                  // racesが1つの場合はAccordionを使わずに表示
                  <Box key={raceIndex}>
                    <Typography variant="h3">{race.name}</Typography>
                    <Box>
                      {race.heats
                        .slice() // 元の配列をコピーして破壊的変更を避ける
                        .sort((a, b) => a.display_order - b.display_order)
                        .map((heat, heatIndex) => (
                          <Box key={heatIndex}>
                            <HeatItem key={heat.id} heat={heat} discipline={discipline} />
                          </Box>
                        ))}
                    </Box>
                  </Box>
                ) : (
                  // racesが複数の場合はAccordionを使用
                  <Accordion key={raceIndex}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${raceIndex}-content`}
                      id={`panel${raceIndex}-header`}
                    >
                      <Typography variant="h3">{race.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {race.heats
                      .slice() // 元の配列をコピーして破壊的変更を避ける
                      .sort((a, b) => a.display_order - b.display_order)
                      .map((heat, heatIndex) => (
                        <Box key={heatIndex}>
                          <HeatItem key={heat.id} heat={heat} discipline={discipline} />
                        </Box>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                )
              ))}
          </Box>
        ))}
      </Box>

    </Box>
  );
};

const HeatItem = ({ heat, discipline }) => {
  // ステータスに応じた文字色を設定する
  const statusColor = heat.status === "completed" ? "blue" : (heat.status === "started" ? "red" : "black");

  return (
    <Box className="heat_item" sx={{
      backgroundColor: '#eee',
      height: '120px',
      margin: '10px',
      borderRadius: '5px',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }}>
      <Box sx={{
        position: 'absolute',
        left: '10px',
        top: '10%',
      }}>
        <i className="fas fa-flag-checkered fa-2x"></i>
      </Box>
      <Box sx={{
        marginLeft: '60px',
        width: '500px'
      }}>
        <Box sx={{
          fontSize: 'medium',
          fontWeight: '700'
        }}>{heat.name}</Box>
        <Box sx={{
          fontSize: 'x-small',
          fontWeight: '700'
        }}>Start Time: {heat.started_at ? new Date(heat.started_at).toLocaleString() : ""}</Box>
        <Box sx={{
          fontSize: 'small',
          fontWeight: '700',
          color: statusColor // 文字色をステータスに応じて設定
        }}>
          [{heat.status.toUpperCase()}]
        </Box>
      </Box>
      <Box sx={{
        marginLeft: 'auto',
        padding: '0 10px'
      }}>
        {renderHeatActions(heat, discipline)}
      </Box>
    </Box>
  );
};

const renderHeatActions = (heat, discipline) => {
  let resultsUrl = `/heats/${heat.id}/heat_results`; // heat_heat_results_path(heat)に相当するURL
  let dispTitle = "リザルト速報";

  if(discipline.url_options.no_result){
    if(discipline.url_options.no_result==="1"){
      resultsUrl = `/heats/${heat.id}/athletes`;
      dispTitle = "選手一覧"
    }
  }

  if (heat.status === "completed") {
    if (heat.heat_replays.length === 0) {
      return (
        <>
          <Button startIcon={<GroupsIcon />} sx={{ minWidth: '120px', marginRight: 1 }} variant="contained" color="primary" href={resultsUrl} target="_blank" rel="noopener noreferrer">選手一覧</Button>
          <Button startIcon={<DoDisturbIcon />} sx={{ minWidth: '120px', marginRight: 1 }} variant="contained" style={{ backgroundColor: 'gray', color: 'white' }} disabled>No Replay</Button>
        </>
        );
    } else {
      return (
        <>
          {renderReplayButtons(heat, discipline)}
          <Button startIcon={<LeaderboardIcon />} sx={{ minWidth: '120px', marginRight: 1 }} variant="contained" color="primary" href={resultsUrl} target="_blank" rel="noopener noreferrer">{dispTitle}</Button>
        </>
      );
    }
  } else if (heat.status === "started") {
    return (
      <>
        {renderLiveButtons(heat, discipline)}
        <Button startIcon={<LeaderboardIcon />} sx={{ minWidth: '120px', marginRight: 1 }} variant="contained" color="primary" href={resultsUrl} target="_blank" rel="noopener noreferrer">{dispTitle}</Button>
      </>
    );
  } else {
    return (
      <>
        <Button startIcon={<GroupsIcon />} sx={{ minWidth: '120px', marginRight: 1 }} variant="contained" color="primary" href={resultsUrl} target="_blank" rel="noopener noreferrer">選手一覧</Button>
        <Button startIcon={<DoDisturbIcon />} sx={{ minWidth: '120px', marginRight: 1 }} variant="contained" style={{ backgroundColor: 'gray', color: 'white' }} disabled>Not yet...</Button>
        
      </>
    );
  }
};



const renderReplayButtons = (heat, discipline) => {

  const replay = heat.heat_replays[heat.heat_replays.length - 1];

  return (
      <>
        <Button
          variant="contained"
          color="primary"
          startIcon={<ReplayIcon />}
          href={replay.broadcast_url}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ minWidth: '120px', marginRight: 1 }}
        >
          Replay
        </Button>

        {discipline.type.name.toLowerCase() === "sailing" && replay.url && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<ThreeDRotationIcon />}
            href={`${replay.broadcast_url}&view=terrain`}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ minWidth: '120px', marginRight: 1 }}
          >
           Replay
          </Button>
        )}

        {!discipline.url_options.no_result && 
        <Button
          href={heat.laptime_url}
          target="_blank"
          rel="noopener noreferrer"
          variant="contained"
          color="primary"
          startIcon={<TimerIcon />}
          sx={{ minWidth: '120px', marginRight: 1 }}
        >
          Lap
        </Button>
        }
 
      </>
    );
};


const renderLiveButtons = (heat, discipline) => {
   return (
      <>
        <Button
          href={heat.map_url}
          target="_blank"
          rel="noopener noreferrer"
          variant="contained"
          color="error"
          startIcon={<ReplayIcon />}
          sx={{ minWidth: '120px', marginRight: 1 }}
        >
          Live
        </Button>

        {discipline.type.name.toLowerCase() === "sailing" && (
          <Button
            href={`${heat.map_url}&view=terrain`}
            target="_blank"
            rel="noopener noreferrer"
            variant="contained"
            color="error"
            startIcon={<ThreeDRotationIcon />}
            sx={{ minWidth: '120px', marginRight: 1 }}
          >
            Live
          </Button>
        )}

        {discipline.type.name.toLowerCase() === "roadrace" && (
          <Button
            href={`${heat.terrain_url}`}
            target="_blank"
            rel="noopener noreferrer"
            variant="contained"
            color="error"
            startIcon={<ThreeDRotationIcon />}
            sx={{ minWidth: '120px', marginRight: 1 }}
          >
            Live
          </Button>
        )}



        <Button
          href={heat.laptime_url}
          target="_blank"
          rel="noopener noreferrer"
          variant="contained"
          color="primary"
          startIcon={<TimerIcon />}
          sx={{ minWidth: '120px', marginRight: 1 }}
        >
          Lap
        </Button>
 
      </>
    );
};


export default DisciplineSelect;

