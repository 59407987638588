import * as React from 'react';
import {useState, useEffect} from 'react';

//import React from 'react';
import { MapContainer, ScaleControl, Polyline, Marker, Polygon, Circle, Popup, LayersControl, TileLayer, useMapEvents, Tooltip, GeoJSON, useMap } from 'react-leaflet';
import L from 'leaflet';
import '!style-loader!css-loader!react-leaflet-fullscreen/dist/styles.css'; 
import { BingLayer } from 'react-leaflet-bing-v2';
import MainPlayer from './MainPlayer';
import LabelMarker from './LabelMarker';
import BuoyLines from './BuoyLines';
import VirtualBuoy from './VirtualBuoy';
import Emergency from './Emergency';
import Online from './online';
import MeasureDistance from '../measurement_distance/MeasureDistance'
import Fullscreen from 'react-leaflet-fullscreen-plugin';
import './map.css';
import '!style-loader!css-loader!leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import Control from './Control';
import Cookies from 'js-cookie';
import axios from 'axios';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41]
});

const bing_key = "AlXkcsmK9XFWxo7VIH9QD1-aXpw4mGNILvxacbsvdfM-z8VvhNLBThCiEVbwdh3p";

export const mapType = {
	  bing: "航空写真",
	  osmBlackAndWhite: "モノクロ",
	  osmMapnik: "カラー",
	  gsi: "国土地理院",
}

//export default class MapBrowser extends React.Component {
export default function MapBrowser(props) {

	const { center, lines, circles, polygons, vbuoys, geojsons, emergencies, formlines, formcircles, formpolygons, formvbuoys, measure_distance, online, knot } = props;
	const f_lines    = formlines ? formlines : []
	const f_circles  = formcircles ? formcircles : []
	const f_polygons = formpolygons ? formpolygons : []
	const f_vbuoys   = formvbuoys ? formvbuoys : []
  const [pos, setPos] = useState(props.pos);
  const [zoom, setZoom] = useState(props.zoom);
  const [height, setHeight] = useState(props.height ? props.height : '90vh');
  const [show_distance_devices, setShowDistanceDevices] = useState([]);
	const ems = emergencies ? emergencies : []
	const maxNativeZoom=18
	const maxZoom=20




  const [contourData10, setContourData10] = useState(null);
  useEffect(() => {
    const fetchContourData = async () => {
      const apiKey = '0e83ad5d93214e04abf37c970c32b641';
      const url = `https://api.msil.go.jp/depth-contour/v2/MapServer/10/query?f=geojson&geometryType=esriGeometryEnvelope&spatialRel=esriSpatialRelIntersects&units=esriSRUnit_Meter&returnGeometry=true`;
      try {
        const response = await axios.get(url, {
						headers: {
								'Ocp-Apim-Subscription-Key': apiKey
						}
				});
				console.log(response.data)
        setContourData10(response.data);
      } catch (error) {
        console.error('Error fetching contour data:', error);
      }
    };
    //fetchContourData();
  }, []);



  useEffect(() => {
    const zoomValue = Cookies.get('zoom');
    if (zoomValue) {
      setZoom(Number(zoomValue));
    }
  }, []);

  const handleShowDistanceDevices = (device) => {
    let d = show_distance_devices;
    if(d.length > 0){
      let prev = d[d.length - 1];
      if(prev.deviceId==device.deviceId){
        setShowDistanceDevices(d.slice(0, -1))
      } else {
        setShowDistanceDevices(d.concat(device))
      } 
    } else {
      setShowDistanceDevices(d.concat(device))
    }
  }

  const initShowDistanceDevices = () => {
    setShowDistanceDevices([])
  }


	//スイッチオフでクリアする
	useEffect(() => {
		if(measure_distance == false){
			initShowDistanceDevices();
		}
  }, [measure_distance]);


  function MyComponent() {
		const map = useMapEvents({
		click: (e) => {
		  props.handleClick(e);
		},
		// zoomが変更された時に実行するコールバック関数
		zoomend: () => {
		  const zoomLevel = map.getZoom();
		  setZoom(zoomLevel); // state更新をしておかないと、再レンダリング時のmap.setZoom(zoom)の実行で以前のzoomレベルに戻ってしまう。
		  Cookies.set('zoom', zoomLevel);
		},
		});
		// zoomなどの情報はmapインスタンスに直接アクセスしないと変更できない。
		// useMap系はmapインスタンスを返すので、そのインスタンスを使ってzoomを変更する。
		// https://react-leaflet.js.org/docs/api-map/
			map.setZoom(zoom);
		return null;
	}

	const options = {
		position: 'topleft', // change the position of the button can be topleft, topright, bottomright or bottomleft, default topleft
		title: 'Show me the fullscreen !', // change the title of the button, default Full Screen
		titleCancel: 'Exit fullscreen mode', // change the title of the button when fullscreen is on, default Exit Full Screen
		content: null, // change the content of the button, can be HTML, default null
		forceSeparateButton: true, // force separate button to detach from zoom buttons, default false
		forcePseudoFullscreen: true, // force use of pseudo full screen even if full screen API is available, default false
		fullscreenElement: false, // Dom element to render in full screen, false by default, fallback to map._container
	};

	let deviceMap = [];
	if( props.deviceMap )
		deviceMap = props.deviceMap;

	const currentMap = props.mapType ? props.mapType : mapType.osmBlackAndWhite;

		//	{currentMap === mapType.bing && <BingLayer bingkey={bing_key} />}
	return(
		<>
			<MapContainer center={center} zoom={zoom} attributionControl={false} style={{ height: height }} >
				<MyComponent />
				{currentMap === mapType.bing && <BingLayer bingkey={bing_key} maxZoom={maxZoom} maxNativeZoom={maxNativeZoom}/>}
				{currentMap === mapType.osmBlackAndWhite && <TileLayer
							attribution= "Map data OpenstreetMap contributors"
							url = "https://api.mapbox.com/styles/v1/satos/cjnjsli1u84uf2stlop5vw0lu/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoic2F0b3MiLCJhIjoiY2planFjN2I1MTh2dzJ5bW94ZTd1YjczcCJ9.VP-67J5C2pO6EkLqGCgMeA"
							maxZoom={maxZoom} maxNativeZoom={maxNativeZoom}
						/>}
				{currentMap === mapType.osmMapnik && <TileLayer
							attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
							url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							maxZoom={maxZoom} maxNativeZoom={maxNativeZoom}
						/>}
				{currentMap === mapType.gsi && <TileLayer
							attribution='&copy; '
							url="https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png"
							maxZoom={maxZoom} maxNativeZoom={maxNativeZoom}
						/>}


				<ScaleControl className="zoom" position="bottomleft" imperial={false} />

				<Fullscreen
					eventHandlers={{
						enterFullscreen: (event) => console.log('entered fullscreen', event),
						exitFullscreen: (event) => console.log('exited fullscreen', event),
					}}
					{...options}
				/>

				<Marker icon={DefaultIcon} position={center}>
					<Popup direction={'top'} >拠点の位置</Popup>
				</Marker>

				<Control>
					{props.topright}
					</Control>

				{/* Lines */}
				{ lines.map( (positions) => (
					<Polyline color={positions.color_code} weight={3} opacity={0.8} key={Math.random()} positions={positions.vertexes}>
						<LabelMarker position={positions.vertexes} name={positions.name} color={positions.color_code} type={"l"} />
					</Polyline>
				))}
				{ f_lines.map( (positions) => (
					<Polyline color={positions.color_code} weight={5} opacity={0.9} key={Math.random()} positions={positions.vertexes} />
				))}

				{/* Polygons */}
				{ polygons.map( (positions) => ( 
					<Polygon key={Math.random()} color={positions.color_code} weight={1} opacity={0.6} positions={positions.vertexes} >
						<LabelMarker position={positions.vertexes} name={positions.name} color={positions.color_code} type={"p"} />
					</Polygon>
				))}
				{ f_polygons.map( (positions) => ( 
					<Polygon key={Math.random()} color={positions.color_code} weight={3} opacity={0.9} positions={positions.vertexes} />
				))}

				{/* Circles */}
				{ circles.map( (circle) => (
					<Circle color={circle.color_code}  fillColor={circle.color_code} weight={1} opacity={0.6} key={Math.random()} center={circle.vertexes.center} radius={circle.vertexes.radius} >
						<LabelMarker position={circle.vertexes.center} name={circle.name} color={circle.color_code} type={"c"} />
					</Circle>
				))}
				{ f_circles.map( (circle) => (
					<Circle color={circle.color_code} fillColor={circle.color_code} weight={3} opacity={0.9} key={Math.random()} center={circle.vertexes.center} radius={circle.vertexes.radius} />
				))}

				{/* Vbuoys */}
				{ vbuoys.map( (circle) => ( 
					<div key={circle.id}>
						<Circle color={circle.color_code} fillColor={circle.color_code} key={Math.random()} center={circle.vertexes.center} radius={circle.vertexes.radius} >
							<LabelMarker position={circle.vertexes.center} name={circle.name} color={circle.color_code} type={"c"} />
						</Circle>
						<VirtualBuoy key={Math.random()} id={circle.id} name={circle.name} center={circle.vertexes.center}
												 measure_distance={props.measure_distance}
												 show_label={props.show_label}
												 handleShowDistanceDevices={handleShowDistanceDevices}
						/>
					</div>
				))}

				{/* GeoJson */}
				{ geojsons.map( (geojson) => ( 
					<GeoJSON data={JSON.parse(geojson.vertexes)} style={{"color":geojson.color_code, "opacity":0.6}}/>
				))}

				{/* 海しるAPI */}
				{contourData10 && <GeoJSON data={contourData10} style={{"color":"#0000ff", "opacity":0.6}}/>}

				{/* Emergency */}
				{ ems.map( (e) => ( 
					<Emergency lat={e.lat} long={e.long} phone_number={e.phone_number} created_at={e.created_at} location={e.location} triage={e.triage} comment={e.comment} id={e.id} history={e.history} />
				))}

				{ f_vbuoys.map( (circle) => ( 
					<div key={circle.id}>
						<Circle color={circle.color_code} fillColor={circle.color_code} key={Math.random()} center={circle.vertexes.center} radius={circle.vertexes.radius} />
						<VirtualBuoy key={Math.random()} id={circle.id} name={circle.name} center={circle.vertexes.center}
												 measure_distance={props.measure_distance}
												 show_label={props.show_label}
												 handleShowDistanceDevices={handleShowDistanceDevices}
						/>
					</div>
				))}


				{/* Marker */}
				{ lines.map( (m) => ( m.vertexes.map( (p) => (
					props.showPins && <Marker color={'#A4C520'}  key={Math.random()} icon={DefaultIcon} position={p} >
						<Popup direction={'top'} > <a href={m.uri && m.uri.js+'/edit'}>{m.name}</a>  </Popup>
					</Marker>
				))))}
				{ polygons.map( (m) => ( m.vertexes.map( (p) => (
					props.showPins && <Marker key={Math.random()} icon={DefaultIcon} position={p} >
						<Popup direction={'top'} > <a href={m.uri && m.uri.js+'/edit'}>{m.name}</a>  </Popup>
					</Marker>
				))))}
				{ circles.map( (m) => ( 
					props.showPins && <Marker color={'#EDAD0B'} key={Math.random()} icon={DefaultIcon} position={m.vertexes.center} >
						<Popup direction={'top'} > <a href={m.uri && m.uri.js+'/edit'}>{m.name}</a>  </Popup>
					</Marker>
				))}
				{ vbuoys.map( (m) => ( 
					props.showPins && <Marker color={'#EDAD0B'} key={Math.random()} icon={DefaultIcon} position={m.vertexes.center} >
						<Popup direction={'top'} > <a href={m.uri && m.uri.js+'/edit'}>{m.name}</a>  </Popup>
					</Marker>
				))}
				{ f_lines.map( (m) => ( m.vertexes.map( (p) => (
					props.showPins && <Marker color={'#A4C520'}  key={Math.random()} icon={DefaultIcon} position={p} >
						<Popup direction={'top'} > <a href={m.uri && m.uri.js+'/edit'}>{m.name}</a>  </Popup>
					</Marker>
				))))}
				{ f_polygons.map( (m) => ( m.vertexes.map( (p) => (
					props.showPins && <Marker key={Math.random()} icon={DefaultIcon} position={p} >
						<Popup direction={'top'} > <a href={m.uri && m.uri.js+'/edit'}>{m.name}</a>  </Popup>
					</Marker>
				))))}
				{ f_circles.map( (m) => ( 
					props.showPins && <Marker color={'#EDAD0B'} key={Math.random()} icon={DefaultIcon} position={m.vertexes.center} >
						<Popup direction={'top'} > <a href={m.uri && m.uri.js+'/edit'}>{m.name}</a>  </Popup>
					</Marker>
				))}
				{ f_vbuoys.map( (m) => ( 
					props.showPins && <Marker color={'#EDAD0B'} key={Math.random()} icon={DefaultIcon} position={m.vertexes.center} >
						<Popup direction={'top'} > <a href={m.uri && m.uri.js+'/edit'}>{m.name}</a>  </Popup>
					</Marker>
				))}


				{/* Player */}
				{Object.keys(deviceMap).map((key, index) => (
					<MainPlayer key={deviceMap[key].id}
													 device={deviceMap[key]}
													 show_label={props.show_label}
													 measure_distance={props.measure_distance}
													 handleShowDistanceDevices={handleShowDistanceDevices}
													 show_line={props.show_line}
													 max_line_length={props.max_line_length}
													 all_lines={deviceMap[key].all_lines}
													 knot={knot}
						/>
				))}

				{/* Start Goal Line */}
				<BuoyLines devices={deviceMap} />


				{/* MeasureDistance */}
				<MeasureDistance devices={show_distance_devices} isshow={props.measure_distance}/>

				<Online online={online} /> 

				<LayersControl position="bottomright">
					<LayersControl.BaseLayer name="Bing">
						<BingLayer bingkey={bing_key} />
					</LayersControl.BaseLayer>
					<LayersControl.BaseLayer name="OSM.BlackAndWhite">
						<TileLayer
							attribution= "Map data OpenstreetMap contributors"
							url = "https://api.mapbox.com/styles/v1/satos/cjnjsli1u84uf2stlop5vw0lu/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoic2F0b3MiLCJhIjoiY2planFjN2I1MTh2dzJ5bW94ZTd1YjczcCJ9.VP-67J5C2pO6EkLqGCgMeA"
						/>
					</LayersControl.BaseLayer>
					<LayersControl.BaseLayer name="OSM.Mapnik">
						<TileLayer
							attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
							url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						/>
					</LayersControl.BaseLayer>
					<LayersControl.BaseLayer name="国土地理院">
						<TileLayer
							attribution='&copy;'
							url="https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png"
						/>
					</LayersControl.BaseLayer>
					<LayersControl.BaseLayer name="国土地理院(写真）">
						<TileLayer
							attribution='&copy;'
							url="https://cyberjapandata.gsi.go.jp/xyz/ort/{z}/{x}/{y}.jpg"
						/>
					</LayersControl.BaseLayer>


				</LayersControl>

			</MapContainer>
		</>
	);
}
