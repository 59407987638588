import React from "react";

const position = {
  position: "absolute",
  top: 0,
  right: 0,
  marginTop: "60px",
  marginRight: "20px",
  zIndex: 1000, // マップの上に表示するため
};

// leafletのControlコンポーネントでは1秒ごとに再描画されるため、自前でコンポーネントを作成
export default function Control({ children }) {
  return (
    <div style={position}>
      {children}
    </div>
  );
}
