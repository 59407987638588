import * as React from 'react';
import {useState, useEffect} from 'react';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import { css } from '@emotion/css';


export default function LabelMarker(props) {
	const { position, name, type, color } = props;

	if(!position)
		return <></>


	let center = {lat:0, lng:0}

	if(type == "c"){
		center = position;
	}else if(type == "p"){
		let lat = 0;
		let lng = 0;
		position.forEach((p)=>{
			lat = lat + p.lat;
			lng = lng + p.lng;
		});
		lat = lat / position.length;
		lng = lng / position.length;
		center = {lat: lat, lng: lng}
	}

	const styles = {
			container: css`
					color: ${color};
					font-size: 12px;
					white-space: nowrap;
					`
	}

	const txt = L.divIcon({html: name, className: styles.container});

	return(
		<Marker position={center} icon={txt} />
	);
}
