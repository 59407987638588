export default class InitializeReplay{

  static initializeSlider(t, replay){
    var dt_from = replay.min_time();
    var dt_to = replay.max_time();
    var min_val = dt_from/1000;
    var max_val = dt_to/1000;

    t.setState({
      dataRange: { min: min_val,
                   max: max_val,
                   step: 1 },
      now_time: [min_val],
    });
  }



  static zeroPad(num, places) {
    var zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
  }

  static formatDT(t) {
      let __dt = new Date((t)*1000);
      let year = __dt.getFullYear();
      let month = this.zeroPad(__dt.getMonth()+1, 2);
      let date = this.zeroPad(__dt.getDate(), 2);
      let hours = this.zeroPad(__dt.getHours(), 2);
      let minutes = this.zeroPad(__dt.getMinutes(), 2);
      let seconds = this.zeroPad(__dt.getSeconds(), 2);
      return year + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ':' + seconds;
  };


  static athleteClicked(obj){

      if(obj.prop('checked')){
          replay.replayDevices.forEach(function(d){
            if (d.id == obj.attr("id")){
              d.marker.highlight = true
              d.marker.focus()//developing
            }
            //d.marker.focus(0)//developing
          })
      }else{
          var id = obj.attr("id")
          replay.replayDevices.forEach(function(d){
            if (d.id == id){
              d.marker.highlight = false
              d.marker.disFocus()//developing
            }
          })
      }
  }
}
